<template>
  <layout-home>

    <router-view />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />
  </layout-home>

</template>

<script>
import LayoutHome from '@core/layouts/layout-home/LayoutHome.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    LayoutHome,
    AppCustomizer,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
